import { inject, ref, computed, reactive } from 'vue';
import { useModelBuilder } from '@matterific/utils';
import { useMatterificFields } from '#imports';
import { isNil, get, set, startsWith, some } from 'lodash-es';

// eslint-disable-next-line no-unused-vars
const useField = (name, schema, context) => {
  // ----------------
  // HANDLE INPUT

  const model = inject('model', {});

  const value = computed({
    get: () => {
      let value = get(model.value, name, null);
      // value = isEmpty(value) ? null : value;
      return value;
    },
    set: (value) => {
      set(model.value, name, value);
      meta.value.dirty = true;
    }
  });

  // ----------------
  // VALIDATION ERRORS

  const allErrors = reactive(inject('errors', {}));
  const errors = computed(() => get(allErrors.value, name, []));

  const hasErrors = computed(() => () => {
    return !!errors.value?.length || some(allErrors?.value, (error, key) => startsWith(key, name));
  });
  // ----------------
  // META DATA

  const meta = ref({
    touched: false,
    dirty: !!value.value,
    valid: computed(() => !errors.value?.length),
    required: computed(() => !!schema?.isRequired)
  });

  // ----------------
  // FIELD HANDLING

  function validate() {
    // meta.value.valid = isValid.value;
  }

  function handleBlur() {
    meta.value.touched = true;
    validate();
  }

  // ----------------
  // FIELDS SETUP

  const fields = useMatterificFields();

  // ----------------
  // RETURN PUBLIC API

  return {
    // State
    model,
    value,

    // Getters : these are computed so they cannot be accidentally mutated
    errors,
    hasErrors,
    meta: computed(() => meta.value),

    // Methods
    getFieldSchema: fields.getFieldSchema,
    is: fields.is,
    bind: fields.bind,
    isVisible: fields.isVisible,
    isDisabled: fields.isDisabled,
    handleBlur,
    validate
  };
};

// eslint-disable-next-line no-unused-vars
const useFieldArray = (name, schema, context) => {
  // ----------------
  // EXTRACT FIELD
  const {
    is,
    bind,
    isVisible,
    isDisabled,
    getFieldSchema,
    model,
    value,
    errors,
    meta,
    handleBlur,
    validate
  } = useField(name);

  // ----------------
  // VALIDATION ERRORS

  const allErrors = inject('errors', {});

  const hasErrors = computed(() => (index) => {
    if (isNil(index)) {
      return (
        !!errors.value?.length || some(allErrors?.value, (error, key) => startsWith(key, name))
      );
    } else {
      return some(allErrors?.value, (error, key) => startsWith(key, `${name}.${index}`));
    }
  });

  // ----------------
  // FIElD HANDLING

  // we pass through the schema to the child fields
  // because arrays may have different schemas for each item,
  // eg: flexible fields

  function add(schema) {
    const item = buildModel(schema);
    value.value ??= []; //safety check
    value.value.push(item);
    handleBlur();
    return item;
  }

  // same as add
  function buildModel(schema) {
    const model = useModelBuilder(schema?.properties);
    model.isOpen = true;

    return model;
  }

  // ----------------

  function remove(index) {
    value.value?.splice(index, 1);
    handleBlur();
  }

  function toggle(item, index) {
    // force open if has errors
    if (hasErrors.value(index)) {
      item.isOpen = true;
    } else {
      item.isOpen = !item.isOpen;
    }
  }

  function move(from, to) {
    value.value.splice(to, 0, value.value.splice(from, 1)[0]);
  }

  function swap(indexA, indexB) {
    [value.value[indexA], value.value[indexB]] = [value.value[indexB], value.value[indexA]];
  }

  // ----------------
  // RETURN PUBLIC API

  return {
    // State
    model,
    value,

    // Getters : these are computed so they cannot be accidentally mutated
    errors,
    hasErrors,
    meta: computed(() => meta.value),

    // Methods
    is,
    bind,
    isVisible,
    isDisabled,
    getFieldSchema,
    handleBlur,
    validate,
    add,
    remove,
    move,
    swap,
    toggle
  };
};

export const useMatterificField = useField;
export const useMatterificFieldArray = useFieldArray;
